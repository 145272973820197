import projectuTalk from "./img/project-utalk.jpg";
import projectDrCarrot from "./img/project-drcarrot.jpg";
import projectXmasCard from "./img/project-xmas.jpg";
import projectDailyPhoto from "./img/project-dailyphoto.jpg";
import projectSweatifyMobile from "./img/project-sweatify-mobile.jpg";
import projectIdealEnergia from "./img/project-ideal-energia.jpg";
import projectVBlock from "./img/project-v-block.jpg";
import projectMakkon from "./img/project-makkon.jpg";
import projectFitasProgresso from "./img/project-fitas-progresso.jpg";
import projectGPSLifetime from "./img/project-gps.jpg";
import projectWordpressJaFuiMandioca from "./img/project-wordpress-jafuimandioca.jpg";
import projectReactjsWeatherApp from "./img/project-reactjs-weather-app.jpg";
import projectDigitalks from "./img/project-digitalks.jpg";
import projectSprylifeMobile from "./img/project-sprylife-mobile.jpg";
import projectLegispedia from "./img/project-legispedia.jpg";
import projectOficina1 from "./img/project-oficina1.jpg";
import projectCovabraViagemDosSonhos from "./img/project-covabra-viagem-dos-sonhos.jpg";
import projectCPA from "./img/project-cpa.jpg";
import projectDrPediu from "./img/project-dr-pediu.jpg";
import projectCiclaInstitucional from "./img/project-cicla-institucional.jpg";
import projectGaleriaDoRock from "./img/project-galeria-do-rock.jpg";
import projectPapoDeGaragem from "./img/project-papo-de-garagem.jpg";
import projectSuperVarejo from "./img/project-super-varejo.jpg";
import projectMegaWhatLanding from "./img/project-megawhat-landing.jpg";
import projectHarryPotterQuebraCabeca3D from "./img/project-harry-potter-quebra-cabeca-3d.jpg";
import projectOleoPeloFuturo from "./img/project-oleo-pelo-futuro.jpg";
import projectBikeAndPark from "./img/project-bike-and-park.jpg";
import projectRicardoOppi from "./img/project-ricardo-oppi.jpg";
import projectTurmaDaMonicaOFilme from "./img/project-turma-da-monica-o-filme.jpg";
import projectVenturo from "./img/project-venturo.jpg";
import projectJuliaoInstitucional from "./img/project-juliao-institucional.jpg";
import projectMegawhatPaginaEquipe from "./img/project-megawhat-pagina-equipe.jpg";
import projectSulamericaAssistenciaInventario from "./img/project-sulamerica-assistencia-inventario.jpg";
import projectLoopaDashboard from "./img/project-loopa-dashboard.jpg";
import projectLoopaCadastro from "./img/project-loopa-cadastro.jpg";
import projectTrainmec from "./img/project-trainmec.jpg";
import projectCompasso from "./img/project-compasso.jpg";
import projectCave from "./img/project-cave.jpg";
import projectParpe from "./img/project-parpe.jpg";
import projectDoc88 from "./img/project-doc88.jpg";
import projectBradescoSeguro from "./img/project-bradesco-seguros.jpg";
import projectCarglass from "./img/project-carglass.jpg";
import projectLifeMattersApp from "./img/project-life-matters-app.jpg";
import projectMiniDreamersDesignersPage from "./img/project-minidreamers-designers-page.jpg";
import projectOrElleOurPages from "./img/project-or-elle-our-pages.jpg";
import projectPrimeEstate from "./img/project-prime-estate.jpg";

const projects = [
    {
        active: true,
        image: projectPrimeEstate,
        titleEn: 'Prime Estate',
        titlePt: 'Prime Estate',
        typeEn: 'Web App',
        typePt: 'Aplicativo Web',
        tecnology: 'react',
        tecnologyTitle: 'React',
        developedReasonEn: 'Personal Project',
        developedReasonPt: 'Projeto Pessoal',
        descriptionEn: 'Real Estate App',
        descriptionPt: 'App de imobiliária',
        link: 'http://andressabertolini.com/project/prime-estate',
    },
    {
        active: true,
        image: projectLegispedia,
        titleEn: 'Legispedia',
        titlePt: 'Legispedia',
        typeEn: 'Web App',
        typePt: 'Aplicativo Web',
        tecnology: 'react',
        tecnologyTitle: 'React',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Legispedia is a tool to study and understand Law',
        descriptionPt: 'A Legispedia é uma ferramenta estudar e entender o Direito',
        link: 'http://andressabertolini.com/project/prime-estate',
    },
    {
        active: true,
        image: projectLoopaDashboard,
        titleEn: 'Loopa Dashboard',
        titlePt: 'Loopa Dashboard',
        typeEn: 'Dashboard',
        typePt: 'Painel',
        tecnology: 'vue',
        tecnologyTitle: 'Vue',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Home of Loopa\'s payment system dashboard, developed with Vue.js',
        descriptionPt: 'Página inicial do painel do sistema de pagamentos da Loopa, desenvolvido em Vue.js',
        link: 'http://andressabertolini.com/project/loopa-dashboard',
    },
    {
        active: true,
        image: projectSuperVarejo,
        titleEn: 'Super Varejo',
        titlePt: 'Super Varejo',
        typeEn: 'Magazine Portal',
        typePt: 'Portal da Revista',
        tecnology: 'vue',
        tecnologyTitle: 'Vue',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'SuperVarejo is a digital magazine focused on publishing content about the supermarket sector',
        descriptionPt: 'A SuperVarejo é uma revista digital com foco na publicação de conteúdo sobre o setor supermercadista',
        link: 'http://andressabertolini.com/project/super-varejo',
    },
    {
        active: true,
        image: projectIdealEnergia,
        titleEn: 'Ideal Energy',
        titlePt: 'Ideal Energia',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'angular',
        tecnologyTitle: 'Angular',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institucional Website in Angular for a B2B alternative energy company',
        descriptionPt: 'Site institucional em Angular para uma empresa de energia alternativa B2B',
        link: 'https://andressabertolini.com/project/ideal-energia/',
    },
    {
        active: true,
        image: projectCovabraViagemDosSonhos,
        titleEn: 'Covabra Viagem dos Sonhos',
        titlePt: 'Covabra Viagem dos Sonhos',
        typeEn: 'Supermarket Promotion',
        typePt: 'Promoção de Supermercado',
        tecnology: 'angular',
        tecnologyTitle: 'Angular',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Page for the dream trip promotion held by the Covabra supermarket',
        descriptionPt: 'Página para a promoção viagem dos sonhos realizada pelo supermercado Covabra',
        link: 'https://andressabertolini.com/project/covabra-viagem-dos-sonhos/',
    },
    {
        active: true,
        image: projectDigitalks,
        titleEn: 'Digitalks',
        titlePt: 'Digitalks',
        typeEn: 'Interactive Menu',
        typePt: 'Menu Interativo',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Scroll navigation menu lobby to access Digitalks course content',
        descriptionPt: 'Menu de navegação scroll em lobby para acessar conteúdo do curso Digitalks',
        link: 'http://andressabertolini.com/project/digitalks',
    },
    {
        active: true,
        image: projectXmasCard,
        titleEn: 'XMas Card',
        titlePt: 'XMas Card',
        typeEn: 'Holiday card maker',
        typePt: 'Criador de cartão de natal',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Personal Project',
        developedReasonPt: 'Projeto Pessoal',
        descriptionEn: 'Site to create a Christmas card and share it with someone special, sending the image or printing',
        descriptionPt: 'Site para criar um cartão de natal e compartilhar com alguém especial, enviando a imagem ou imprimindo',
        link: 'http://andressabertolini.com/project/xmas',
    },
    {
        active: true,
        image: projectDailyPhoto,
        titleEn: 'Daily Photo',
        titlePt: 'Daily Photo',
        typeEn: 'Wallpaper download',
        typePt: 'Downloas de papel de parede',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Personal Project',
        developedReasonPt: 'Projeto Pessoal',
        descriptionEn: 'In this site you can download everyday a free image to use as your desktop wallpaper',
        descriptionPt: 'Nesse site todo dia um papel de parede diferente está disponível para download',
        link: 'http://victoriysuka.com/dailyphoto/',
    },
    {
        active: false,
        image: projectReactjsWeatherApp,
        titleEn: 'Weather App',
        titlePt: 'App de clima',
        typeEn: 'Web App',
        typePt: 'Web App',
        tecnology: 'react',
        developedReasonEn: 'Personal Project',
        developedReasonPt: 'Projeto Pessoal',
        descriptionEn: 'This ReactJS project shows the weather in 4 cities. When you switch between them, the background changes according to the weather and if it\'s night or day.',
        descriptionPt: 'Esse app em ReactJS mostra o clima de 4 países. Quando você troca de país, a imagem de fundo muda de acordo com o clima e se é dia ou noite naquele país.',
        link: 'https://andressabertolini.github.io/weather-app/',
    },
    {
        active: false,
        image: projectDrCarrot,
        titleEn: 'Dr. Carrot',
        titlePt: 'Dr. Carrot',
        typeEn: 'Online test',
        typePt: 'Teste online',
        tecnology: 'html',
        developedReasonEn: 'Personal Project',
        developedReasonPt: 'Projeto Pessoal',
        descriptionEn: 'Dr. Carrot is a humorous quiz where you can discovery your fruit/vegetable of lucky.',
        descriptionPt: 'Dr. Carrot é um teste bem humorado aonde você pode descobrir qual é a sua fruta/vegetal da sorte.',
        link: 'http://andressabertolini.com/project/dr-carrot',
    },
    {
        active: false,
        image: projectTurmaDaMonicaOFilme,
        titleEn: 'Turma da Mônica The Movie',
        titlePt: 'Turma da Mônica O Filme',
        typeEn: 'Movie Website',
        typePt: 'Site do filme',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'The website of the live action movie of the classical brazilian comics "Turma da Mônica"',
        descriptionPt: 'O website de divulgação do filme live action dos clássicos dos quadrinhos Turma da Mônica',
        link: '',
    },
    {
        active: false,
        image: projectHarryPotterQuebraCabeca3D,
        titleEn: 'Harry Potter 3D Puzzle',
        titlePt: 'Harry Potter Quebra-Cabeça 3D',
        typeEn: 'Product Landing Page',
        typePt: 'Landing Page do produto',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'This landing page was developed for divulge the 3d puzzle series of Harry Potter',
        descriptionPt: 'Essa landing page foi desenvolvida para divulgar o quebra-cabeça 3D do Harry Potter, com vários cenários do filme para montar',
        link: '',
    },
    {
        active: false,
        image: projectBradescoSeguro,
        titleEn: 'Bradesco Insurance',
        titlePt: 'Bradesco Seguro',
        typeEn: 'Landing Page',
        typePt: 'Landing Page',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Landing page for a bank who offers car insurance',
        descriptionPt: 'Landing page para divulgar o seguro para carros da Bradesco',
        link: '',
    },
    {
        active: false,
        image: projectSulamericaAssistenciaInventario,
        titleEn: 'Sulamerica Inventory Assistance',
        titlePt: 'Sulamerica Assistência Inventário',
        typeEn: 'Form page',
        typePt: 'Página de formulário',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'This website is an inventory assistance form with 4 pages and a lot of different types of inputs',
        descriptionPt: 'Formulário de assistência inventário da Sulamérica, contém aplicações de vários estilos de inputs de formulário',
        link: '',
    },  
    {
        active: false,
        image: projectGaleriaDoRock,
        titleEn: 'Rock Gallery',
        titlePt: 'Galeria do Rock',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Website of the iconic build, and so tourist spot in São Paulo, Rock Gallery. You can find a lot of Rock N\' Roll there',
        descriptionPt: 'Site da famosa galeria do rock, ponto turistico de São Paulo. Você econconta vários artigos de Rock N\' Roll lá',
        link: '',
    },
    {
        active: false,
        image: projectLoopaCadastro,
        titleEn: 'Loopa Sign Up Form',
        titlePt: 'Cadastro Loopa',
        typeEn: 'Form page',
        typePt: 'Página de formulário',
        tecnology: 'vue',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Registration flow in various stages of Loopa\'s payment system.',
        descriptionPt: 'Fluxo de cadastro em várias etapas do sistema de pagamentos da Loopa.',
        link: '',
    },
    {
        active: false,
        image: projectFitasProgresso,
        titleEn: 'Progresso Ribbons',
        titlePt: 'Fitas Progresso',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institutional website of the manufacturer of ribbons and bows Fitas Progresso, which also maintains the "Crafts Club" project',
        descriptionPt: 'Site institucional do fabricante de fitas e laços Fitas Progresso, que também mantém o projeto Clube de Artesanato',
        link: '',
    },
    {
        active: false,
        image: projectDoc88,
        titleEn: 'Doc88',
        titlePt: 'Doc88',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institutional website of the Doc88 technology company',
        descriptionPt: 'Site institucional da empresa de tecnologia Doc88',
        link: '',
    },
    {
        active: false,
        image: projectCave,
        titleEn: 'Cave',
        titlePt: 'Cave',
        typeEn: 'Agency Website',
        typePt: 'Site da Agência',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Website of the agency I worked for, Cave Digital, with the company\'s client portfolio',
        descriptionPt: 'Site da agência que trabalhava, Cave Digital, com o portfólio dos clientes da empresa',
        link: '',
    },
    {
        active: false,
        image: projectMegaWhatLanding,
        titleEn: 'Megawhat',
        titlePt: 'Megawhat',
        typeEn: 'Landing Page',
        typePt: 'Landing Page',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Megawhat is a platform to bring information about the energy market',
        descriptionPt: 'Megawhat é a plataforma para trazer informação sobre o mercado de energia',
        link: '',
    },
    {
        active: false,
        image: projectVBlock,
        titleEn: 'V-Block',
        titlePt: 'V-Block',
        typeEn: 'Landing Page',
        typePt: 'Landing Page',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Landing page developed using the Tailwind framework. The client is a leather producer with nanotechnology to inactivate the SARS-Cov-2 virus',
        descriptionPt: 'Landing page desenvolvida utilizando o framework Tailwind. Cliente produtor de couro com nanotecnologia para inativar o vírus SARS-Cov-2',
        link: '',
    },
    {
        active: false,
        image: projectOficina1,
        titleEn: 'Oficina 1',
        titlePt: 'Oficina 1',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Company responsible for implementing and maintaining TOTVS ERP Protheus',
        descriptionPt: 'Empresa responsável por implementação e manutenção do ERP Protheus da TOTVS',
        link: '',
    },
    {
        active: false,
        image: projectCPA,
        titleEn: 'CPA Consulting',
        titlePt: 'CPA Consulting',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Business process management and automation company',
        descriptionPt: 'Empresa de automação e gestão de processos de negócios',
        link: '',
    },
    {
        active: false,
        image: projectCompasso,
        titleEn: 'Compasso',
        titlePt: 'Compasso',
        typeEn: 'Educational Platform',
        typePt: 'Plataforma educacional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Compasso\'s course platform, system with course visualization and storage of current stage progress',
        descriptionPt: 'Plataforma de cursos da Compasso, sistema com visualização de cursos e armazenamento do progresso da etapa atual',
        link: '',
    },
    {
        active: false,
        image: projectDrPediu,
        titleEn: 'Dr. Pediu',
        titlePt: 'Dr. Pediu',
        typeEn: 'Mobile App Website',
        typePt: 'Site de Aplicativo',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Prescription and medical management application website',
        descriptionPt: 'Site de aplicativo de prescrição e gestão médica',
        link: '',
    },
    {
        active: false,
        image: projectCiclaInstitucional,
        titleEn: 'Cicla',
        titlePt: 'Cicla',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institutional website of Cicla, an NGO that carries out social recycling projects',
        descriptionPt: 'Site institucional da Cicla, uma ONG que faz projetos sociais de reciclagem',
        link: '',
    },
    {
        active: false,
        image: projectPapoDeGaragem,
        titleEn: 'Papo de Garagem',
        titlePt: 'Papo de Garagem',
        typeEn: 'Landing Page',
        typePt: 'Landing Page',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Landing page for the garage chat event',
        descriptionPt: 'Landing page para o evento papo de garagem',
        link: '',
    },
    {
        active: false,
        image: projectJuliaoInstitucional,
        titleEn: 'Julião Coelho Advogados',
        titlePt: 'Julião Coelho Advogados',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institutional website for the Julião Coelho law firm',
        descriptionPt: 'Site institucional para o escritório de advocacia Julião Coelho',
        link: '',
    },
    {
        active: false,
        image: projectMegawhatPaginaEquipe,
        titleEn: 'Megawhat',
        titlePt: 'Megawhat',
        typeEn: 'Team Page',
        typePt: 'Página da equipe',
        tecnology: 'html',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Creation of the team page for the Megawhat institutional website.',
        descriptionPt: 'Criação da página de equipe para o site institucional de Megawhat.',
        link: '',
    },
    {
        active: false,
        image: projectVenturo,
        titleEn: 'Venturo',
        titlePt: 'Venturo',
        typeEn: 'Institucional Wesite',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institutional website of the Venturo consultancy',
        descriptionPt: 'Site institucional da Venturo assessoria',
        link: '',
    },
    {
        active: false,
        image: projectParpe,
        titleEn: 'Parpe',
        titlePt: 'Parpe',
        typeEn: 'App Website',
        typePt: 'Página do Aplicativo',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Freelance',
        developedReasonPt: 'Freelance',
        descriptionEn: 'Website for car rental app parpe',
        descriptionPt: 'Site para o aplicativo de aluguel de carros parpe',
        link: '',
    },
    {
        active: false,
        image: projectCarglass,
        titleEn: 'Carglass',
        titlePt: 'Carglass',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Carglass is a company that offers various services for changing glass and car repairs',
        descriptionPt: 'Carglass é uma empresa que oferece vários serviços para troca de vidros e reparos automotivos',
        link: '',
    },
    {
        active: true,
        image: projectuTalk,
        titleEn: 'uTalk',
        titlePt: 'uTalk',
        typeEn: 'Online course',
        typePt: 'Curso Online',
        tecnology: 'html',
        tecnologyTitle: 'HTML',
        developedReasonEn: 'Personal Project',
        developedReasonPt: 'Projeto Pessoal',
        descriptionEn: 'uTalk is a website that sells courses to learn English online',
        descriptionPt: 'uTalk é um site que vende cursos de para aprender inglês online',
        link: 'http://andressabertolini.com/project/utalk',
    },
    {
        active: false,
        image: projectSweatifyMobile,
        titleEn: 'Sweatify App',
        titlePt: 'Sweatify App',
        typeEn: 'Mobile App',
        typePt: 'Aplicativo mobile',
        tecnology: 'ionic',
        tecnologyTitle: 'Ionic',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Ionic + AngularJS mobile app for subscribers take online exercise classes',
        descriptionPt: 'Aplicativo em Ionic + AngularJS para assinantes terem aulas de exercício físico online',
        link: '',
    },
    {
        active: false,
        image: projectSprylifeMobile,
        titleEn: 'Sprylife App',
        titlePt: 'Sprylife App',
        typeEn: 'Mobile App',
        typePt: 'Aplicativo Mobile',
        tecnology: 'ionic',
        tecnologyTitle: 'Ionic',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Sprylife is a platform designed for students to find their personal trainer',
        descriptionPt: 'A Sprylife é uma plataforma desenvolvida para alunos encontrarem seu personal trainers',
        link: '',
    },
    {
        active: false,
        image: projectLifeMattersApp,
        titleEn: 'Life Matters',
        titlePt: 'Life Matters',
        typeEn: 'Mobile App',
        typePt: 'Aplicativo Mobile',
        tecnology: 'ionic',
        tecnologyTitle: 'Ionic',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Meditation application in ionic Life Matters, with courses related to the theme',
        descriptionPt: 'Aplicativo de meditação em ionic LIfe Matters, com cursos relacionados ao tema',
        link: '',
    },
    {
        active: false,
        image: projectGPSLifetime,
        titleEn: 'GPS Lifetime',
        titlePt: 'GPS Lifetime',
        typeEn: 'News portal',
        typePt: 'Portal de Notícias',
        tecnology: 'wordpress',
        tecnologyTitle: 'Wordpress',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'GPS Lifetime Magazine News Portal in the capital of Brazil, Brasilia',
        descriptionPt: 'Portal de notícias da revista GPS Lifetime de Brasília',
        link: '',
    },
    {
        active: false,
        image: projectOleoPeloFuturo,
        titleEn: 'Óleo pelo Futuro',
        titlePt: 'Óleo pelo Futuro',
        typeEn: 'Campaign Site',
        typePt: 'Site de Campanha',
        tecnology: 'wordpress',
        tecnologyTitle: 'Wordpress',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Oil recycling campaign website',
        descriptionPt: 'Site para campanha de reciclagem de óleo',
        link: '',
    },
    {
        active: false,
        image: projectWordpressJaFuiMandioca,
        titleEn: 'I used to be cassava',
        titlePt: 'Já fui mandioca',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'wordpress',
        tecnologyTitle: 'Wordpress',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institucional website for a client who sells biodegradable packages',
        descriptionPt: 'Site Institucional para cliente que produz embalagens biodegradáveis feitas de mandioca',
        link: '',
    },
    {
        active: false,
        image: projectBikeAndPark,
        titleEn: 'Bike & Park',
        titlePt: 'Bike & Park',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'wordpress',
        tecnologyTitle: 'Wordpress',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Creation of the team page for the Megawhat institutional website',
        descriptionPt: 'Criação da página de equipe para o site institucional de Megawhat',
        link: '',
    },
    {
        active: false,
        image: projectRicardoOppi,
        titleEn: 'Ricardo Oppi',
        titlePt: 'Ricardo Oppi',
        typeEn: 'Institucional Website',
        typePt: 'Site Institucional',
        tecnology: 'wordpress',
        tecnologyTitle: 'Wordpress',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Institutional website Ricardo Oppi, workshop for restoration of old vehicles and automotive repair',
        descriptionPt: 'Site institucional Ricardo Oppi, oﬁcina de restauração de veículos antigos e reparação automotiva',
        link: '',
    },
    {
        active: false,
        image: projectMakkon,
        titleEn: 'Makkon',
        titlePt: 'Makkon',
        typeEn: 'Product Catalog',
        typePt: 'Catálogo de produtos',
        tecnology: 'woocommerce',
        tecnologyTitle: 'WooCommerce',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Catalog for a company that sells office chairs where the client can register and manage the products',
        descriptionPt: 'Catálogo para empresa de vende cadeiras de escritório aonde o cliente pode cadastrar e gerenciar os produtos',
        link: '',
    },
    {
        active: false,
        image: projectTrainmec,
        titleEn: 'Trainmec',
        titlePt: 'Trainmec',
        typeEn: 'Car accessories E-commerce',
        typePt: 'E-commerce de acessórios para carro',
        tecnology: 'woocommerce',
        tecnologyTitle: 'WooCommerce',
        developedReasonEn: 'Freelance',
        developedReasonPt: 'Freelance',
        descriptionEn: 'E-commerce for car accessories',
        descriptionPt: 'E-commerce de acessorios para carros',
        link: '',
    },
    {
        active: false,
        image: projectOrElleOurPages,
        titleEn: 'Or & Elle',
        titlePt: 'Or & Elle',
        typeEn: 'Institucional Pages',
        typePt: 'Páginas Institucionais',
        tecnology: 'shopify',
        tecnologyTitle: 'Shopify',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Development of the pages "Our Gold" and "Our Story"',
        descriptionPt: 'Desenvolvimento das páginas "Nosso Ouro" e "Nossa História"',
        link: '',
    },
    {
        active: false,
        image: projectMiniDreamersDesignersPage,
        titleEn: 'Mini Dreamers',
        titlePt: 'Mini Dreamers',
        typeEn: 'Search/Filter Page',
        typePt: 'Página de busca/filtro',
        tecnology: 'shopify',
        tecnologyTitle: 'Shopify',
        developedReasonEn: 'Developed at Work',
        developedReasonPt: 'Devenvolvido no Trabalho',
        descriptionEn: 'Filter and search page to browse the clothing designers in the store, you can filter by letter or search by term',
        descriptionPt: 'Pagina de filtro e busca para navegar entre os designers de roupas da loja, você pode filtrar por letra ou perquisar por termo',
        link: '',
    }
];

export default projects;