
import Header from '../layouts/Header';

const NotFound = () => {
    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-9 offset-md-3 col-index">
                    <Header />

                    <div className="content-div p-30 col-md-12">
                        <h1>404</h1>
                        <p>Page not found</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default NotFound;