import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import { Link } from "react-router-dom";

const Tag = ({name, background, icon, translation, link}) => {
    const {language, setLanguage} = useContext(GlobalContext);
    const content = (
        <span className="skill tag white align-middle mb-2" style={{backgroundColor: background}}>
            <i className={"icon icon-"+ icon + " bg-white"}></i>
            {translation && language == 'pt' ? translation : name}
        </span>
    );

    return(
        <li className="mb-5">
            {link ? <Link to={"/projects/"+ link}>{content}</Link> : content}
        </li>
    );
}

export default Tag;