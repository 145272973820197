const CardCompany = ({country, university, year, image, name, type, position, period, description}) => {
    return(
        <div className={(university == false ? "header-line":"") + " bg-white border-gray shadow dark-gray h-full p-15 rounded-small dark-mode-bg dark-mode-white"}>
            <p className="text-center card-company-year bold dark-pink">{year}</p>
            <div className="bg-light-gray bg-cover bg-center w-80 h-80 rounded-full mx-auto mb-10 relative z-1 border-5-gray" 
            style={{backgroundImage: `url(${image})`,marginTop: '-56px'}}></div>

            <p className="text-center text-uppercase font-10">
                {country == 'Brasil' || country == 'Brazil'?
                    <i className="icon-brazil icon-small mr-3"></i> 
                :''}
                {country == 'EUA' || country == 'USA'?
                    <i className="icon-usa icon-small mr-3"></i> 
                :''}
                {country}
            </p>
            <h3 className="text-center font-16 bold m-0">{name}</h3>
            <p className="text-center text-uppercase font-10 mb-20">{type}</p>

            <h3 className="font-16 bold m-0">{position}</h3>
            <p className="text-uppercase font-10 mb-20">{period}</p>

            <p className="font-14">{description}</p>
        </div>            
    )

}

export default CardCompany;