import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";

const Header = () => {
    const {language, setLanguage} = useContext(GlobalContext);
    const {darkMode, setDarkMode} = useContext(GlobalContext);
    const {toggleMenu, setToggleMenu} = useContext(GlobalContext);

    const toggleDarkMode = () => {
        if(darkMode == false){
            setDarkMode(true);
            document.body.classList.add('dark-mode');
        }else{
            setDarkMode(false);
            document.body.classList.remove('dark-mode');
        }
    };

    const menuMobile = () => {
        console.log(toggleMenu);
        if(toggleMenu == false){
            setToggleMenu(true);
            document.getElementById('navMenu').classList.add('open');
        }else{
            setToggleMenu(false);
            document.getElementById('navMenu').classList.remove('open');
        }
    }

    return(
        <div className="header">
            <div className="row">
                <div className="col-7">
                    <p className="languages text-uppercase font-10 inline-block dark-mode-white">
                        <span className="p-8 border-right-gray cursor-pointer hover-gray" onClick={() => setLanguage('pt')}>
                            <i className="icon-brazil mr-8 align-middle"></i> 
                            <span className="pr-15 mobile-pr-5">Português</span>
                        </span>
                        <span className="p-8 cursor-pointer hover-gray" onClick={() => setLanguage('en')}>
                            <i className="icon-usa mr-8 align-middle ml-15 mobile-ml-5"></i>
                            <span>English</span>
                        </span>
                    </p>                        
                </div>   
                <div className="col-5">
                    <p className="text-right">
                        <label className="switch">
                            <input type="checkbox" onClick={() => toggleDarkMode()} />
                            <span className="slider round">
                                <i className="icon-moon absolute switch-night"></i>
                                <i className="icon-sun absolute switch-day"></i>
                            </span>
                        </label>
                        <i className="icon-menu align-middle ml-20 icon bg-pink mt-1" onClick={() => menuMobile()}></i>
                    </p>
                </div>                 
            </div>


        </div>
    )
}

export default Header;
