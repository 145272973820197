import {useContext, useEffect} from 'react';
import GlobalContext from "../context/GlobalContext";
import Header from '../layouts/Header';
import PageTitle from "../components/PageTitle";
import CardCompany from "../components/CardCompany";
import CardCourse from "../components/CardCourse";

import companyAcaoSocial from "../img/company-acao-social.jpg";
import companyPrimeCursos from "../img/company-prime-cursos.jpg";
import companyIped from "../img/company-iped.jpg";
import companySchoolOfNet from "../img/company-school-of-net.jpg";
import companySuporteDeWeb from "../img/company-suporte-de-web.jpg";
import companySoftblue from "../img/company-softblue.jpg";
import companyUniversidadeXti from "../img/company-universidade-xti.jpg";
import companyW3Schools from "../img/company-w3schools.jpg";
import companyMidiaOffice from "../img/company-midiaoffice.jpg";
import companyTrendi from "../img/company-trendi.jpg";
import companyCaveDigital from "../img/company-cavedigital.jpg";
import companyNetalico from "../img/company-netalico.jpg";
import companyAnhembiMorumbi from "../img/company-anhembimorumbi.jpg";
import companyUniversityOfPennsylvania from "../img/company-university-of-pennsylvania.jpg";
import companyJoaoRibeiro from "../img/company-joao-ribeiro.jpg";
import companyFundacaoBradesco from "../img/company-fundacao-bradesco.jpg";
import companyShopify from "../img/company-shopify.jpg";
import companyBigCommerce from "../img/company-bigcommerce.png";
import companyIBM from "../img/company-ibm.png";
import eventGitKon from "../img/event-gitkon.png";

const Experience = () =>{
    const {language, setLanguage} = useContext(GlobalContext);

    useEffect(() => {
        document.body.classList.add('hide-sidebar-mobile');
    },[]); 
    
    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-9 offset-md-3 col-index">
                    <Header/>
                    <div className="content-padding p-30 col-md-12">
                        {language == 'en'?                        
                            <PageTitle
                                title="Work Experience"
                                description="This is the timeline of the companies I've worked for."
                            />
                        :''}
                        {language == 'pt'?                        
                            <PageTitle
                                title="Experiência"
                                description="Confira a linha do tempo das empresas aonde já trabalhei."
                            />
                        :''}

                        <div className="row mt-110 pb-5">
                            <div className="col-md-3 col-line">
                                {language == 'en' ? 
                                <CardCompany 
                                    country="USA"
                                    university={false}
                                    year="2021 - 2024"
                                    image={companyNetalico}
                                    name="Netalico"
                                    type="Digital Agency"
                                    position="Shopify Developer"
                                    period="3 years and 3 months"
                                    description="Activities: Shopify e-commerce maintenance. Configuration of shopify plugins, development of new pages for stores."
                                />
                                :''}
                                {language == 'pt' ? 
                                <CardCompany 
                                    country="EUA"
                                    university={false}
                                    year="2021 - 2024"
                                    image={companyNetalico}
                                    name="Netalico"
                                    type="Agência Digital"
                                    position="Front-End Developer"
                                    period="3 anos e 3 meses"
                                    description="Atividades: Manutenção de e-commerce em Shopify. Configuração de plugins shopify, desenvolvimento de novas páginas para lojas."
                                />
                                :''}
                            </div>
                            <div className="col-md-3 col-line">
                                {language == 'en' ? 
                                <CardCompany 
                                    country="Brazil"
                                    university={false}
                                    year="2017 - 2021"
                                    image={companyCaveDigital}
                                    name="Cave Digital"
                                    type="Digital Agency"
                                    position="Front-End Developer"
                                    period="3 years and 8 months"
                                    description="Activities: Development of websites, landing pages, mobile ionic apps, web applications in angular and in reactjs, wordpress themes development."
                                />
                                :''}
                                {language == 'pt' ? 
                                <CardCompany 
                                    country="Brasil"
                                    university={false}
                                    year="2017 - 2021"
                                    image={companyCaveDigital}
                                    name="Cave Digital"
                                    type="Agência Digital"
                                    position="Front-End Developer"
                                    period="3 anos e 8 meses"
                                    description="Atividades: Desenvolvimento de sites, landing pages, aplicativos mobile em ionic, aplicações web em angular e em reactjs, desenvolvimento de temas wordpress."
                                />
                                :''}
                            </div>
                            <div className="col-md-3 col-line">
                                {language == 'en' ? 
                                <CardCompany 
                                    country="Brazil"
                                    university={false}
                                    year="2016 - 2017"
                                    image={companyTrendi}
                                    name="Trend-i"
                                    type="Digital Agency"
                                    position="Front-End Intern"
                                    period="1 year and 4 months"
                                    description="Activities: Email marketing coding, client websites update."
                                />
                                :''}
                                {language == 'pt' ? 
                                <CardCompany 
                                    country="Brasil"
                                    university={false}
                                    year="2016 - 2017"
                                    image={companyTrendi}
                                    name="Trend-i"
                                    type="Agência Digital"
                                    position="Estagiária de Front-End"
                                    period="1 ano e 4 meses"
                                    description="Atividades: codificação de e-mail marketing, manutenção de sites dos clientes."
                                />
                                :''}
                            </div>
                            <div className="col-md-3 col-line">
                                {language == 'en' ? 
                                <CardCompany 
                                    country="Brazil"
                                    university={false}
                                    year="2015 - 2016"
                                    image={companyMidiaOffice}
                                    name="Midia Office"
                                    type="Software Maker Company"
                                    position="Front-End Intern"
                                    period="1 year and 3 months"
                                    description="Activities: Development and update of the institutional company website, web pages coding, portals and intranets for integration with the company's ERP system."
                                />
                                :''}
                                {language == 'pt' ? 
                                <CardCompany 
                                    country="Brasil"
                                    university={false}
                                    year="2015 - 2016"
                                    image={companyMidiaOffice}
                                    name="Midia Office"
                                    type="Empresa de software"
                                    position="Estagiária de Front-End"
                                    period="1 ano e 3 meses"
                                    description="Atividades: Desenvolvimento e manutenção do site da empresa, desenvolvimento de sites, portais e intranets para integração com o sistema ERP da empresa."
                                />
                                :''}
                            </div>
                        </div>

                        {language == 'en' ?              
                            <PageTitle
                                title="Education"
                                description="In this section you can see my graduation degree and some courses I attend to."
                            />
                        :''}
                        {language == 'pt' ?                   
                            <PageTitle
                                title="Formação"
                                description="Nessa sessão você encontra minha formação acadêmica e alguns cursos que já fiz."
                            />
                        :''}

                        <div className="row mt-60">
                            <div className="col-md-6">
                                {language == 'en' ?
                                    <h3 className="font-18 dark-pink mb-10 bold mobile-text-center">Graduation</h3>
                                :''}
                                {language == 'pt' ?
                                    <h3 className="font-18 dark-pink mb-10 bold mobile-text-center">Graduação</h3>
                                :''}
                                <div className="pt-36 mb-10">
                                    {language == 'en' ?
                                        <CardCompany 
                                            type="Technical degree"
                                            university={true}
                                            image={companyAnhembiMorumbi}
                                            name="Systems Analysis and Development"
                                            position="Anhembi Morumbi University"
                                            period="2015 - 2017"
                                        />
                                    :''}
                                    {language == 'pt' ?
                                        <CardCompany 
                                            type="Tecnólogo"
                                            university={true}
                                            image={companyAnhembiMorumbi}
                                            name="Análise e Desenvolvimento de Sistemas"
                                            position="Universidade Anhembi Morumbi"
                                            period="2015 - 2017"
                                        />
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-30">
                                <h3 className="font-18 dark-pink mb-10 bold mobile-text-center">
                                    {language == 'en' ?
                                        <>Courses and certifications</>
                                    :''}
                                    {language == 'pt' ?
                                        <>Cursos e Certificados</>
                                    :''}
                                </h3>
                            </div>
                            <div className="col-md-6">
                                <CardCourse 
                                    image={companyIBM}
                                    year="2024"
                                    title="Developing Front End Apps with React"
                                    titlePt="Desenvolvendo aplicativos front-end com React"
                                    school="IBM"
                                    duration="14"
                                />
                                <CardCourse 
                                    image={companyBigCommerce}
                                    year="2023"
                                    title="BigDev Certification"
                                    titlePt="Certificação BigDev"
                                    school="BigCommerce University"
                                    duration="42"
                                />
                                <CardCourse 
                                    image={companyShopify}
                                    year="2021"
                                    title="Design and Theming Fundamentals"
                                    titlePt="Fundamentos de Design e Temas"
                                    school="Shopify Partner Academy"
                                    duration="7"
                                />
                                <CardCourse 
                                    image={companyUniversityOfPennsylvania}
                                    year="2020"
                                    title="English for Career Development"
                                    titlePt="Inglês para Desenvolvimento de Carreira"
                                    school="University of Pennsylvania"
                                    schoolPt="Universidade da Pensilvânia"
                                    duration="40"
                                />
                                {/*
                                    <CardCourse 
                                        image={companyJoaoRibeiro}
                                        year="2020"
                                        title="ReactJS"
                                        school="João Ribeiro"
                                        duration="43"
                                    />
                                */}
                                <CardCourse 
                                    image={companyW3Schools}
                                    year="2016"
                                    title="Javascript"
                                    school="W3Schools"
                                    duration="60"
                                />
                                {/*
                                    <CardCourse 
                                        image={companyUniversidadeXti}
                                        year="2015"
                                        title="Javascript"
                                        school="XTI University"
                                        schoolPt="Universidade XTI"
                                        duration="46"
                                    />
                                */}
                                {/*
                                    <CardCourse 
                                        image={companySuporteDeWeb}
                                        year="2014"
                                        title="Complete website with PHP"
                                        titlePt="Site completo com PHP"
                                        school="Suporte de Web"
                                        duration="22"
                                    />
                                */}
                            </div>
                            <div className="col-md-6">
                                <CardCourse 
                                    image={companySoftblue}
                                    year="2015"
                                    title="Programming Logic"
                                    titlePt="Lógica de Programação"
                                    school="Softblue"
                                    duration="20"
                                />
                                {/*
                                    <CardCourse 
                                        image={companySchoolOfNet}
                                        year="2014"
                                        title="XHTML, CSS and Tableless"
                                        titlePt="XHTML, CSS e Tableless"
                                        school="School of Net"
                                        duration="40"
                                    />
                                */}
                                {/*
                                    <CardCourse 
                                        image={companySchoolOfNet}
                                        year="2014"
                                        title="PHP"
                                        school="School of Net"
                                        duration="10"
                                    />
                                */}
                                <CardCourse 
                                    image={companyFundacaoBradesco}
                                    year="2014"
                                    title="Inovating with CSS"
                                    titlePt="Inovando com CSS"
                                    school="Bradesco Foundation"
                                    schoolPt="Fundação Bradesco"
                                    duration="26"
                                />
                                <CardCourse 
                                    image={companyFundacaoBradesco}
                                    year="2014"
                                    title="Advanced HTML"
                                    titlePt="HTML Avançado"
                                    school="Bradesco Foundation"
                                    schoolPt="Fundação Bradesco"
                                    duration="64"
                                />
                                {/*
                                    <CardCourse 
                                        image={companyIped}
                                        year="2014"
                                        title="Photoshop"
                                        school="IPED Courses"
                                        schoolPt="IPED Cursos"
                                        duration="80"
                                    />
                                */}
                                {/*
                                    <CardCourse 
                                        image={companyPrimeCursos}
                                        year="2014"
                                        title="Basic HTML"
                                        titlePt="HTML Básico"
                                        school="Prime Courses"
                                        duration="25"
                                    />
                                */}
                                <CardCourse 
                                    image={companyAcaoSocial}
                                    year="2010"
                                    title="Intensive English"
                                    titlePt="Inglês Intensivo"
                                    school="Our Lady of Fatima Social Action"
                                    schoolPt="Ação Social Nossa Senhora de Fátima"
                                    duration="400"
                                />
                            </div>
                            <div className="col-md-12 mt-30">
                                <h3 className="font-18 dark-pink mb-10 bold mobile-text-center">
                                    {language == 'en' ?
                                        <>Events and conferences</>
                                    :''}
                                    {language == 'pt' ?
                                        <>Eventos e Conferências</>
                                    :''}
                                </h3>
                            </div>
                            <div className="col-md-6">
                                <CardCourse 
                                    image={eventGitKon}
                                    year="2024"
                                    title="GitKon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Experience;