import React, {useContext, useState, useEffect} from "react";
import GlobalContext from "../context/GlobalContext";
import Header from '../layouts/Header';
import PageTitle from "../components/PageTitle";
import CardProject from "../components/CardProject";
import { useParams } from "react-router-dom";

import projects from "../projects";

const Projects = () => {
    const {language, setLanguage} = useContext(GlobalContext);
    const [openMenu, setMenu] = useState(false);
    const [query, setQuery] = useState("");
    const { id } = useParams();
    

    const [filter, setFilter] = useState("");
    var filterTecnology = "";
    var filterParam = "";

    if(query == ""){
        if(filter == "wordpress"){
            filterTecnology = projects.filter(x => x.tecnology ===  "wordpress" || x.tecnology ===  "woocommerce");
        }else if(filter == ""){
            filterTecnology = projects;
        }else{
            filterTecnology = projects.filter(x => x.tecnology ===  filter);
        }
    }else{
        filterTecnology = query;
    }

    const handleSearch = (e) =>{        
        var query = e.target.value.toLowerCase();
        filterTecnology = "";
        filterTecnology = projects.filter((x) =>
            x.titleEn.toLowerCase().indexOf(query) !== -1
        );
        setQuery(filterTecnology);
    }

    useEffect(() => {
        document.body.classList.add('hide-sidebar-mobile');
        //console.log(projects.length+" projetos");

        if(id != "all"){
            setFilter(id)
        }
    },[]);
    
    return(
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9 offset-md-3 col-index">
                        <Header/>
                        <div className="content-div p-30 col-md-12">
                            {language === 'en' ?                            
                            <PageTitle
                                title="Projects"
                                description="In this section you can see some projects I developed for the companies I worked and for study in my free time."
                                note="NOTE: For legal reasons, the link isn't available for all the projects developed as an employee in a company. Logos, texts and images have been replaced to protect the brand privacy in the available links"
                            />
                            :''}
                            {language === 'pt'?
                            <PageTitle
                                title="Projetos"
                                description="Veja nessa página alguns projetos que desenvolvi para as empresas que trabalhei e também para fins de estudo."
                                note="NOTA: Por motivos legais, os links não estão disponíveis para todos os projetos desenvolvidos para empresas. Logos, textos e imagens foram substituídos para proteger a privacidade da marca nos links disponíveis"
                            />
                            :''}
                            <div className="row">
                                <div className="col-md-12 mb-20">
                                    <div className="projects-header">
                                        <div className="search">
                                            {language === 'en' ? 
                                                <input type="text" className="input-search dark-mode-white dark-mode-border-pink"
                                                    placeholder="Search"
                                                    onChange={e => handleSearch(e)}
                                                    />
                                            :''}
                                            {language === 'pt' ? 
                                                <input type="text" className="input-search dark-mode-white dark-mode-border-pink"
                                                    placeholder="Buscar"
                                                    onChange={e => handleSearch(e)}
                                                    />
                                            :''}
                                            <i className="icon icon-search bg-dark-pink"></i>
                                        </div>
                                        <div className="filter">
                                            <div className="filter-title-width float-left cursor-pointer" onClick={() => setMenu(!openMenu)}>
                                                <i className="icon icon-filter bg-dark-pink align-middle mr-8"></i>
                                                {language === 'en' ?   
                                                    <span>Filter</span>
                                                :''}
                                                {language === 'pt' ?   
                                                    <span>Filtrar</span>
                                                :''}
                                            </div>
                                            <div className="filter-width float-left pr-20" onClick={() => setMenu(!openMenu)}>
                                                {filter == '' ?
                                                <span className="bg-dark-pink tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-coding bg-white align-middle mr-8"></i>
                                                    {language === 'en' ?   
                                                        <span>All</span>
                                                    :''}
                                                    {language === 'pt' ?   
                                                        <span>Tudo</span>
                                                    :''}
                                                </span>
                                                : ''}
                                                {filter == 'wordpress' ?
                                                <span className="bg-wordpress tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-wordpress bg-white align-middle mr-8"></i>
                                                    Wordpress
                                                </span>
                                                : ''}
                                                {filter == 'woocommerce' ?
                                                <span className="bg-woocommerce tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-woocommerce mr-8"></i>
                                                    WooCommerce
                                                </span>
                                                : ''}
                                                {filter == 'react' ? 
                                                <span className="bg-react tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-react bg-white align-middle mr-8"></i>
                                                    React
                                                </span>
                                                : ''}
                                                {filter == 'ionic' ?
                                                <span className="bg-ionic tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-ionic bg-white align-middle mr-8"></i>
                                                    Ionic
                                                </span>
                                                : ''}
                                                {filter == 'html' ?
                                                <span className="bg-html tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-html bg-white mr-8"></i>
                                                    HTML
                                                </span>
                                                : ''}
                                                {filter == 'angular' ?
                                                <span className="bg-angular tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-angular bg-white align-middle mr-8"></i>
                                                    Angular
                                                </span>
                                                : ''}
                                                {filter == 'vue' ?
                                                <span className="bg-vue tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-vue bg-white align-middle mr-8"></i>
                                                    Vue
                                                </span>
                                                : ''}
                                                {filter == 'shopify' ?
                                                <span className="bg-shopify tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-shopify bg-white align-middle mr-8"></i>
                                                    Shopify
                                                </span>
                                                : ''}
                                            </div>
                                            <div className="ml-20 float-left cursor-pointer" onClick={() => setMenu(!openMenu)}>
                                                <i className="icon icon-arrow-down bg-dark-pink"></i>
                                            </div>
                                            { openMenu == true ?
                                            <div className="filter-list filter-width">
                                                <span className="bg-dark-pink tag white align-middle mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter(""); setMenu(false)}}>
                                                    <i className="icon icon-coding bg-white align-middle mr-8"></i>
                                                    {language === 'en' ?   
                                                        <span>All</span>
                                                    :''}
                                                    {language === 'pt' ?   
                                                        <span>Tudo</span>
                                                    :''}
                                                </span>
                                                <span className="bg-html tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("html"); setMenu(false)}}>
                                                    <i className="icon icon-html bg-white mr-8"></i>
                                                    HTML
                                                </span>
                                                <span className="bg-react tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("react"); setMenu(false)}}>
                                                    <i className="icon icon-react bg-white mr-8"></i>
                                                    React
                                                </span>
                                                <span className="bg-angular tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("angular"); setMenu(false)}}>
                                                    <i className="icon icon-angular bg-white mr-8"></i>
                                                    Angular
                                                </span>
                                                <span className="bg-vue tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("vue"); setMenu(false)}}>
                                                    <i className="icon icon-vue bg-white mr-8"></i>
                                                    Vue
                                                </span>
                                                <span className="bg-ionic tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("ionic"); setMenu(false)}}>
                                                    <i className="icon icon-ionic bg-white mr-8"></i>
                                                    Ionic
                                                </span>
                                                <span className="bg-wordpress tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("wordpress"); setMenu(false)}}>
                                                    <i className="icon icon-wordpress bg-white mr-8"></i>
                                                    Wordpress
                                                </span>
                                                <span className="bg-woocommerce tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("woocommerce"); setMenu(false)}}>
                                                    <i className="icon icon-woocommerce mr-8"></i>
                                                    WooCommerce
                                                </span>
                                                <span className="bg-shopify tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("shopify"); setMenu(false)}}>
                                                    <i className="icon icon-shopify bg-white mr-8"></i>
                                                    Shopify
                                                </span>
                                            </div>
                                            :''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {language === 'en' ? 
                                filterTecnology.map((project) => (
                                    project.active && (
                                    <div className="col-md-4 mb-30">
                                        <CardProject
                                            image={project.image}
                                            title={project.titleEn}
                                            type={project.typeEn}
                                            tecnology={project.tecnology}
                                            tecnologyTitle={project.tecnologyTitle}
                                            developedReason={project.developedReasonEn}
                                            description={project.descriptionEn}
                                            link={project.link}
                                            className="h-full"
                                        />
                                    </div>
                                    )
                                ))
                                :''}
                                {language === 'pt' ? 
                                filterTecnology.map((project) => (
                                    project.active && (
                                        <div className="col-md-4 mb-30">
                                            <CardProject
                                                image={project.image}
                                                title={project.titlePt}
                                                type={project.typePt}
                                                tecnology={project.tecnology}
                                                developedReason={project.developedReasonPt}
                                                description={project.descriptionPt}
                                                link={project.link}
                                                className="h-full"
                                            />
                                        </div>
                                        )
                                ))
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )

}

export default Projects;