import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

const CardCourse = ({image, year, title, titlePt, school, schoolPt, duration}) => {

    const {language, setLanguage} = useContext(GlobalContext);
    const durationContent = language == "pt" ? "horas" : "hours";
    const schoolContent = language == "pt" && schoolPt ? schoolPt : school;
    const extraClass = !school && !duration ? "mt-6" : "";
    return(
        <div className="bg-white border-gray shadow dark-gray p-15 mb-10 rounded-small dark-mode-bg dark-mode-white">
            <div className="bg-light-gray mr-15 bg-cover bg-center w-50 h-50 rounded-full mx-auto mb-10 relative z-1 border-5-gray float-left" 
            style={{backgroundImage: `url(${image})`}}></div>

            <div className={extraClass+" ml-60"}>
                <p className="text-uppercase font-10">{year}</p>
                <h3 className="font-16 bold m-0">
                    {language == "pt" && titlePt ? titlePt : title}
                </h3>
                {school ? <p className="font-14">{schoolContent}</p> : ""}
                {duration ? <p className="text-uppercase font-10">
                    {duration} {durationContent}
                </p> : ""}
            </div>
        </div>
    )
}

export default CardCourse;