import { useState, useEffect } from "react";

const CardProject = ({
    image,
    title,
    type,
    tecnology,
    tecnologyTitle,
    developedReason,
    description,
    link
}) => {

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const renderContent = () => (
        <>
            <img src={image} className="w-full" alt="Project" />
            <div className="p-15">
                <div className="row mb-20">
                    <div className="col-md-6">
                        <h3 className="dark-pink font-18 m-0 bold">{title}</h3>
                        <p className="text-uppercase font-10">{type}</p>
                    </div>
                    <div className="col-md-6 text-center px-n5">
                        <span className={`bg-${tecnology} tag white mb-2 ml-10 mr-10`}>
                            <i className={`icon icon-${tecnology} bg-white mr-3`}></i>
                            {tecnologyTitle}
                        </span>
                        <p className="text-uppercase font-10">{developedReason}</p>
                    </div>
                </div>
                <p className="font-14 mb-10">{description}</p>
            </div>
        </>
    );

    return (
        <div className="h-100 d-block">
            <div
                className={`${
                    link ? "card-link" : "no-link"
                } card-project bg-white border-gray shadow dark-gray h-full rounded-small overflow-hidden dark-mode-bg dark-mode-white`}
            >
                {link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {renderContent()}
                    </a>
                ) : (
                    renderContent()
                )}
            </div>
        </div>
    );
};

export default CardProject;